import { useEffect } from 'react'
import axios from 'axios';
import { useQuiz } from '../context/QuizContext'
import { ScreenTypes } from '../types'
import { Question } from '../data/QuizQuestions'


export const useDataApi = () => {
    const { setQuestions, currentScreen, quizDetails } = useQuiz()
    const { selectedQuizTopic } = quizDetails
    
    useEffect(() => {
        let topic_id = 0
        const questions: Question[] = []
        const SUB_TOPIC = {
            'Persone fisiche e giuridiche': 1,
            'Diritti reali': 2,
            'Obbligazioni': 3,
            'Contratti': 4,
            'Responsabilità extracontrattuale': 5,
            'Rapporti di famiglia': 6,
            'Successioni per causa morte': 7,
            'Esame completo': 8
        }

        for (const [key, value] of Object.entries(SUB_TOPIC)) {
            if(key === selectedQuizTopic){
                topic_id = value
            }
        }

        if (currentScreen === ScreenTypes.QuizDetailsScreen) {
            axios.get("https://api.quizdiritto.it/api/" + topic_id + "/get_quiz").then((response) => {

                var quizzes_json = response.data;

                for (const quiz of quizzes_json) {
                    console.log(quiz)
                    const x = {
                        question: String(quiz['domanda']),
                        choices: [String(quiz['risposte']['a']), String(quiz['risposte']['b']), String(quiz['risposte']['c']), String(quiz['risposte']['d'])],
                        type: 'boolean',
                        correctAnswers: [String(quiz['risposte'][quiz['risposte']['risposta_esatta']])],
                        score: 1,
                    }
                    questions.push(x)
                }

                setQuestions(questions)

            });

        }
    }, [currentScreen, selectedQuizTopic, setQuestions])




}

export default useDataApi
