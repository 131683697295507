import { personefisicheegiuridiche } from './personefisicheegiuridiche'
import { dirittireali } from './dirittireali'
import { obbligazioni } from './obbligazioni'
import { contratti } from './contratti'
import { responsabilitaextracontrattuale } from './responsabilitaextracontrattuale'
import { rapportidifamiglia } from './rapportidifamiglia'
import { successionipercausamorte } from './successionipercausamorte'
import { esamecompleto } from './esamecompleto'


// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

type Choice = string
type CorrectAnswers = string[]

export type Question = {
  question: string
  choices: Choice[]
  type: string
  correctAnswers: CorrectAnswers
  score: number
  code?: string
  image?: string
}

export type Topic = {
  topic: string
  level: string
  totalQuestions: number
  totalScore: number
  totalTime: number
  questions: Question[]
}

export const quiz: Record<string, Topic> = {
  'Persone fisiche e giuridiche': personefisicheegiuridiche,
  'Diritti reali': dirittireali,
  'Obbligazioni': obbligazioni,
  'Contratti': contratti,
  'Responsabilità extracontrattuale': responsabilitaextracontrattuale,
  'Rapporti di famiglia': rapportidifamiglia,
  'Successioni per causa morte': successionipercausamorte,
  'Esame completo': esamecompleto,
}
