import { ReactNode } from 'react'
import { ReactComponent as Family } from '../assets/icons/family.svg'
import { ReactComponent as Baby } from '../assets/icons/baby.svg'
import { ReactComponent as Home } from '../assets/icons/home.svg'
import { ReactComponent as Handshake } from '../assets/icons/handshake.svg'
import { ReactComponent as Contract } from '../assets/icons/contract.svg'
import { ReactComponent as Mallet } from '../assets/icons/mallet.svg'
import { ReactComponent as Death } from '../assets/icons/death.svg'
import { ReactComponent as Exam } from '../assets/icons/exam.svg'


type QuizTopic = {
  title: string
  icon: ReactNode
  disabled?: boolean
}

export const quizTopics: QuizTopic[] = [
  {
    title: 'Persone fisiche e giuridiche',
    icon: <Baby />,
  },
  {
    title: 'Diritti reali',
    icon: <Home />,
  },
  {
    title: 'Obbligazioni',
    icon: <Handshake />,
  },
  {
    title: 'Contratti',
    icon: <Contract />,
  },
  {
    title: 'Responsabilità extracontrattuale',
    icon: <Mallet />,
  },
  {
    title: 'Rapporti di famiglia',
    icon: <Family />,
  },
  {
    title: 'Successioni per causa morte',
    icon: <Death />,
  },
  {
    title: 'Esame completo',
    icon: <Exam />,
  },
]