// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'


export const esamecompleto: Topic = {
  topic: 'Esame completo',
  level: 'Medio',
  totalQuestions: 30,
  totalScore: 30,
  totalTime: 1800,
  questions: [
  
    {
      question: 'Cariacando il quiz attendi...',
      choices: ['...', '...'],
      type: 'boolean',
      correctAnswers: ['False'],
      score: 1,
    },
  ],
}
